import {
    AppBar,
    Toolbar,
    Typography,
    InputBase,
    makeStyles,
    IconButton,
    Box,
    Link,
} from "@material-ui/core";
import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import EmailIcon from "@material-ui/icons/Email";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SearchBar from "./SearchBar";
import PersonIcon from "@material-ui/icons/Person";
import { Link as RouterLink, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        height: "10vh",
        backgroundImage: "linear-gradient(to left, #0250c5 0%, #d43f8d 100%)",
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "flext-start",
        alignItems: "center",
        paddingRight: theme.spacing(4),
    },
    grow: {
        flexGrow: 1,
    },
    icons: {
        fill: "white",
    },
    title: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(4),
        fontStyle: "italic",
    },
}));

export default function TopBar(props) {
    const classes = useStyles();
    const history = useHistory();
    const { goToProfile } = props;

    return (
        <AppBar position="fixed">
            <Toolbar className={classes.container}>
                <IconButton>
                    <MenuIcon fontSize="large" className={classes.icons} />
                </IconButton>

                <Typography variant="h4" className={classes.title}>
                    Social Media
                </Typography>
                <SearchBar />
                <div className={classes.grow}></div>
                <Box ml={2} />
                <IconButton>
                    <EmailIcon className={classes.icons} />
                </IconButton>
                <IconButton>
                    <NotificationsIcon className={classes.icons} />
                </IconButton>
                
                <IconButton>
                        <PersonIcon className={classes.icons} />
                    </IconButton>

                <IconButton>
                    <ExitToAppIcon className={classes.icons} />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}
