import { Box, Button, TextareaAutosize, TextField, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    buttons: {
        marginLeft: theme.spacing(3),
    },
    input: {
        background: "white",
    }
}));

export default function PostNew(props) {
    const classes = useStyles();
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const {onPost} = props;

    function handleClickClear() {
        setContent("");
        setTitle("");
    }

    function handlePost(e) {
        e.preventDefault();
        if (onPost) {
            const timestamp = new Date();
            const time = `${timestamp.getFullYear()}-${String(timestamp.getMonth() + 1).padStart(2, "0")}-${String(timestamp.getDate()).padStart(2, "0")}`;
            let post = {
                title: title,
                content: content,
                username: "Niklas",
                time: time,
            };
            onPost(post);
        }
        setContent("");
        setTitle("");
    }

    return (
        <form onSubmit={handlePost}>
        <Box display="flex" flexDirection="column" width="100%">
            <TextField
                maxRows={1}
                placeholder="Title here"
                variant="outlined"
                className={classes.input}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
            <Box mt={2} />
            <TextField
                rows={8}
                multiline
                placeholder="Anything want to share with us?"
                variant="outlined"
                className={classes.input}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <input
                    accept="image/*"
                    id="upload-btn"
                    multiple
                    type="file"
                    style={{ display: "none" }}
                />
                <label htmlFor="upload-btn">
                    <Button
                        startIcon={<CloudUploadIcon />}
                        variant="contained"
                        component="span"
                        className={classes.buttons}
                        color="primary"
                    >
                        UPLOAD
                    </Button>
                </label>
                <Button startIcon={<SendIcon />} variant="contained" className={classes.buttons}  color="secondary" type="submit">
                    SHARE
                </Button>
                <Button startIcon={<ClearIcon />} variant="contained" className={classes.buttons} onClick={handleClickClear} color="default">
                    CLEAR
                </Button>
            </Box>
        </Box>
        </form>
    );
}
