import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center",
        width: "30vw",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
	selector: {
		
	},
}));

export default function SearchBar(props) {
    const classes = useStyles();
    const [query, setQuery] = useState("");

    return (
        <Paper className={classes.root}>
            <InputBase
                className={classes.input}
                placeholder="Search..."
                inputProps={{ "aria-label": "search" }}
				value={query}
				onChange={(e) => setQuery(e.target.value)}
            />
            <IconButton
                className={classes.iconButton}
                aria-label="search"
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
