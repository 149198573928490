import { Box, Grid, Snackbar } from "@material-ui/core";
import { useEffect, useState } from "react";
import "./App.css";
import PostItem from "./components/PostItem";
import PostNew from "./components/PostNew";
import TopBar from "./components/TopBar";

const POST_URL = "https://social-media.ztgniklas.workers.dev/";

function App() {
    const [posts, setPosts] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    useEffect(() => {
        getPosts();
    }, []);

    function getPosts() {
        fetch(POST_URL + "posts")
            .then((resp) => resp.json())
            .then((data) => setPosts(data))
            .catch((error) => {
				console.error('Error:', error);
				setOpenAlert(true);
			});
    }

    function onPost(post) {
        fetch(POST_URL + "posts", {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(post),
        })
            .then((resp) => {
                getPosts();
            })
            .catch((error) => {
				console.error('Error:', error);
				setOpenAlert(true);
			});
    }
    return (
        <Box display="flex" flexDirection="column" alignItems="stretch">
            <Snackbar
                open={openAlert}
                autoHideDuration={1000}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
				onClose={() => setOpenAlert(false)}
				message="There's something wrong... Try again."
            >
            </Snackbar>
            <TopBar />
            <Grid container component={Box} mt={12}>
                <Grid item xs={1} sm={2}></Grid>
                <Grid container item xs={10} sm={8}>
                    <Grid item xs={12} sm={12}>
                        <PostNew onPost={onPost} />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        spacing={2}
                        component={Box}
                        mt={2}
                    >
                        {posts
                            ? posts.map((post, idx) => (
                                  <Grid item xs={12} sm={12} md={6} key={idx}>
                                      <PostItem
                                          username={post.username}
                                          date={post.time}
                                          content={post.content}
                                          title={post.title}
                                      />
                                  </Grid>
                              ))
                            : null}
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={2}></Grid>
            </Grid>
        </Box>
    );
}

export default App;
